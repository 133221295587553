<!--
* @description:
* @fileName pageEdit.vue
* @author hvv
* @date 2022/01/18 09:36:48
!-->
<template>
  <el-dialog
    v-model="dialogFormVisible"
    title="审核"
    width="60%"
    @close="close"
  >
    <el-form
      ref="formRef"
      :inline="true"
      label-width="140px"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="类型名称" prop="status">
        <el-select v-model="form.status">
          <template v-for="(item, index) in statusArr" :key="index">
            <el-option :value="item.key" :label="item.value" v-if="item.value"></el-option>
          </template>
        </el-select>
      </el-form-item>
      <el-form-item
        label="驳回原因"
        prop="reason"
        v-if="form.status == 'REFUSE'"
      >
        <el-input v-model="form.reason" maxLength='20'/>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>
<script>
  import {
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'
  import { RealNameAudit } from '@/api/user'

  export default defineComponent({
    name: 'pageEdit',
    emits: ['fetch-data'],
    setup(props, { emit }) {
      const { proxy } = getCurrentInstance()

      const state = reactive({
        formRef: null,
        treeRef: null,
        checkMenu: [],
        pageList: [],
        statusArr: [
          {
            key: 'PASS',
            value: '通过',
          },
          {
            key: 'REFUSE',
            value: '驳回',
          },
          {
            key: 'PENDING',
            value: '',
          },
        ],
        form: {},
        rules: {
          status: [
            { required: true, trigger: 'change', message: '请选择审核状态' },
          ],
          reason: [
            { required: true, trigger: 'blur', message: '请输入驳回原因' },
          ],
        },
        title: '',
        dialogFormVisible: false,
        list: [],
      })

      const showEdit = async (row) => {
        state.form = Object.assign({}, row)
        state.form.status = state.form.status == 'PENDING' ? "" : state.form.status
        state.dialogFormVisible = true
      }

      const close = () => {
        state['formRef'].resetFields()
        state.form = {}
        state.dialogFormVisible = false
      }

      const save = () => {
        state['formRef'].validate(async (valid) => {
          if (valid) {
            let data = await RealNameAudit(state.form)
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
            emit('fetch-data')
            close()
          }
        })
      }

      return {
        ...toRefs(state),
        showEdit,
        close,
        save,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .vab-tree-border {
    height: 200px;
    padding: $base-padding;
    overflow-y: auto;
    border: 1px solid #dcdfe6;
    border-radius: $base-border-radius;
  }

  .demo-drawer__content {
    height: 85vh;
    overflow: auto;
  }
  .demo-drawer__footer {
    position: fixed;
    right: 20px;
    bottom: 10px;
  }
</style>
